import { useParams } from "react-router-dom";
import Activity from "./activity";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import PerformanceDials from "./performance";
import { useEffect, useState } from "react";
import Loading from "components/loading";
import { useDispatch } from "react-redux";
import moment from "moment";
import { saveFilterDates } from "store/dashboard";

const Notification = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const [type, setType] = useState<number>(0);

  const { data: notificationData } = useDashboardFetchDelegateDataQuery({
    key: param?.key ?? "",
    type: param?.type ?? "",
  });

  const profile = notificationData?.profile;
  function storeFilterDates() {
    const profile = notificationData?.profile;
    let startDate = profile?.rangeStartDate ?? moment().format("Y-m-d");
    let endDate = profile?.rangeEndDate ?? moment().format("Y-m-d");

    let appliedFilterDates = {
      filterStartDate: moment(startDate).format("YYYY-MM-DD"),
      filterEndDate: moment(endDate).format("YYYY-MM-DD"),
      trackingStartDate: moment(startDate).format("YYYY-MM-DD"),
      trackingEndDate: moment(endDate).format("YYYY-MM-DD"),
    };

    dispatch(saveFilterDates(appliedFilterDates));
  }

  useEffect(() => {
    storeFilterDates();
    setType(notificationData?.settings?.type ?? 0);
  }, [notificationData]);
  return <>{renderActivityDials(type)}</>;

  function renderActivityDials(type: number) {
    return type == 2 ? (
      <PerformanceDials {...profile} />
    ) : type == 1 ? (
      <Activity />
    ) : (
      <Loading />
    );
  }
};

export default Notification;
