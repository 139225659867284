import { showAllDials } from "store/dashboard";
import { useDispatch } from "react-redux";
import { AllDials } from "./AllDials";
import { TrackingData } from "./TrackingData";
import AddRow from "sections/tracking-data/Add";
import EditDelegate from "sections/tracking-data/Edit";
import { Panel, PanelGroup } from "rsuite";
import DeleteTrackingDataRow from "sections/tracking-data/Delete";
import DialGroupSlider from "./DialGroupSlider";

const Performance = (userDetails: any) => {
  const dispatch = useDispatch();

  return (
    <>
      <PanelGroup accordion defaultActiveKey={3} bordered>
        <div className="single-user-wrapper bg-white p-3 rounded-xl ">
          <button
            className="bg-gray-200 absolute z-[99] right-[28px] font-bold px-5 py-[10px] hover:bg-gray-400 hover:text-white transition-all ease-in-out duration-500"
            onClick={() => dispatch(showAllDials(true))}
          >
            See All Dials
          </button>
          <Panel
            header={
              <div className="flex flex-row justify-between items-center my-1">
                <h2 className="font-bold text-lg pl-7">Performance</h2>
              </div>
            }
            eventKey={3}
            id="panel3"
            className="dashboard-accordion-panel"
          >
            <hr className="mx-auto mt-2 mb-0 bg-blue border-blue-600 border-1 dark:bg-blue-700 "></hr>
            <DialGroupSlider {...userDetails} />
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 mt-5"></div>
          </Panel>
        </div>
        <AllDials {...userDetails} />
        <TrackingData />
        <AddRow />
        <DeleteTrackingDataRow />
        <EditDelegate />
      </PanelGroup>
    </>
  );
};

export default Performance;
