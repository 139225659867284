import { useState, useMemo } from "react";
import Table from "components/table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import AvatarComponent, { AvatarStyle } from "components/my-avatar";
// import { avatarDefaultValuesObject } from "utils/helperFunctions";
import CustomModal from "components/modals/CustomModal";
import {
  useGetUserAvatarsQuery,
  useUpdateUserAvatarMutation,
} from "store/preferences/myAvatarApi";
import { Button, useToaster } from "rsuite";
import { toastError, toastSuccess } from "components/toasts";
import { useFetchGlobalDashboardDataQuery } from "store/dashboard/DashboardSlice";
import { getTheHealthId } from "utils/helperFunctions";
import { DEFAULT_AVATAR } from "assets/default-avatar";
import { useParams } from "react-router-dom";
import { TDelegateAvatarType } from "types/dashboard";

const PERCENTAGE_MAPPER: { [key: number]: string } = {
  1: "0% - 39.99%",
  2: "40% - 59.99%",
  3: "60% - 79.99%",
  4: "80% - 89.99%",
  5: "90% - 100%",
};
const DEFAULT_AVATAR_DATA = {
  accessoriesType: "Prescription02",
  avatarStyle: "Transparent",
  clotheColor: "PastelBlue",
  clotheType: "Hoodie",
  eyeType: "Happy",
  eyebrowType: "Default",
  facialHairType: "Blank",
  hairColor: "BrownDark",
  mouthType: "Smile",
  skinColor: "Light",
  topType: "LongHairMiaWallace",
  isDefaultAvatar: true,
};
export default function MyAvatarSection({
  getSingleAvatar,
  singleHealthId,
  singleAvatarStyling,
  defaultAvatarValues,
  isGlobalAvatar,
  isLoggedInUser,
  delegateData,
}: {
  getSingleAvatar?: boolean;
  singleHealthId?: number;
  singleAvatarStyling?: {
    width: string;
    height: string;
    display: "block" | "inline";
  };
  defaultAvatarValues?: { [key: string]: string };
  isGlobalAvatar?: {
    isPerformance: boolean;
  };
  isLoggedInUser?: boolean;
  delegateData?: TDelegateAvatarType;
}) {
  const params = useParams();
  const [currentAvatarOptions, setCurrentAvatarOptions] = useState({});
  // NOTE: this state contains details and also work as a flag top open CustomModal
  const [isModalOpen, setIsModalOpen] = useState<
    | false
    | {
        healthId: number;
        avatar: { [key: string]: string };
      }
  >(false);
  const { data: allAvatars } = useGetUserAvatarsQuery(delegateData);
  const { data: averageAvatar } = useFetchGlobalDashboardDataQuery({
    isDelegateView: params?.key,
  });
  // healthId
  const [updateAvatar] = useUpdateUserAvatarMutation();
  const columnHelper = createColumnHelper<{
    healthId: number;
    avatar: { [key: string]: string };
    activity: string;
    action: [];
  }>();

  const toast = useToaster();
  const handleAvatarUpdate = async () => {
    try {
      if (isModalOpen) {
        await updateAvatar({
          healthId: isModalOpen.healthId,
          ...(Object.keys(currentAvatarOptions).length > 0
            ? currentAvatarOptions
            : isModalOpen.avatar),
        });
        setIsModalOpen(false);
        toast.push(toastSuccess({ message: "Avatar updated successfully" }));
        setCurrentAvatarOptions({});
      }
    } catch (error) {
      toast.push(toastError({ message: "Something went wrong" }));
    }
  };

  const COLUMNS = useMemo(
    () => [
      columnHelper.accessor("avatar", {
        header: "Avatar",
        cell: (info) => {
          const { avatar, healthId } = info.row.original;
          return (
            <AvatarComponent
              defaultAvatarComponent={
                <DEFAULT_AVATAR
                  avatarLevel={healthId}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              }
              avatarStyle={AvatarStyle.Circle}
              // TODO iniline display
              style={{ width: "100px", height: "100px", display: "inline" }}
              {...avatar}
            />
          );
        },
      }),
      columnHelper.accessor("activity", {
        header: "Activity",
        cell: (info) => {
          return <h5>{PERCENTAGE_MAPPER[info.row.original.healthId]}</h5>;
        },
      }),
      columnHelper.accessor("action", {
        header: "Action",
        cell: (info) => {
          return (
            <Button
              appearance="primary"
              onClick={() => {
                setIsModalOpen({
                  avatar: info.row.original.avatar,
                  healthId: info.row.original.healthId,
                });
              }}
            >
              Update Personal Avatar
            </Button>
          );
        },
      }),
    ],
    [allAvatars]
  );

  const getAvatars = () => {
    if (isLoggedInUser && allAvatars) {
      return allAvatars.filter((item) => item.healthId == singleHealthId);
    }
    if (isGlobalAvatar && averageAvatar) {
      if (isGlobalAvatar.isPerformance) {
        singleHealthId = getTheHealthId(
          averageAvatar?.avatarPerformance.average
        );
      }
      if (!isGlobalAvatar.isPerformance) {
        singleHealthId = getTheHealthId(
          averageAvatar?.avatarActivities.average
        );
      }
    }

    if (allAvatars && singleHealthId) {
      return allAvatars.filter((item) => item.healthId === singleHealthId);
    }
    if (allAvatars) {
      const healthIdPresent = allAvatars?.map((item) => item.healthId);
      let newAllAvatars = [...allAvatars];
      for (let key of Object.keys(PERCENTAGE_MAPPER)) {
        if (!healthIdPresent?.includes(parseInt(key))) {
          newAllAvatars = [
            ...newAllAvatars,
            {
              healthId: parseInt(key),
              avatar: DEFAULT_AVATAR_DATA,
            },
          ];
        }
      }
      return newAllAvatars;
    }
    return [];
  };

  return allAvatars ? (
    !getSingleAvatar ? (
      <>
        <Table
          //@ts-ignore
          tableData={{ data: getAvatars(), columns: COLUMNS }}
          // tableTitle={"My Avatar"}
        />
        {isModalOpen && (
          <CustomModal
            successBtnHandler={handleAvatarUpdate}
            successBtnText={"submit"}
            cancelBtnText={"close"}
            title="Personal Avatar"
            cancelBtnHandler={() => {
              setIsModalOpen(false);
              setCurrentAvatarOptions({});
            }}
            children={
              <AvatarComponent
                //@ts-ignore
                setSelectedAvatarOptions={setCurrentAvatarOptions}
                className="width-[200px] h-[200px] m-auto"
                avatarStyle={AvatarStyle.Transparent}
                {...isModalOpen?.avatar}
                isForm={true}
                isDefaultAvatar={false}
              />
            }
          />
        )}
      </>
    ) : (
      <AvatarComponent
        defaultAvatarComponent={
          <DEFAULT_AVATAR
            avatarLevel={singleHealthId ?? 0}
            style={{
              width: singleAvatarStyling?.width,
              height: singleAvatarStyling?.height,
            }}
          />
        }
        //@ts-ignore
        avatarStyle={AvatarStyle.Circle}
        // TODO iniline display
        style={singleAvatarStyling}
        // className={"width-[50px] height-[100px] inline"}
        {...(defaultAvatarValues
          ? defaultAvatarValues
          : isGlobalAvatar
          ? getAvatars()[0]?.avatar ?? DEFAULT_AVATAR_DATA
          : DEFAULT_AVATAR_DATA)}
      />
    )
  ) : (
    <></>
  );
}
