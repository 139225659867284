import { useParams } from "react-router-dom";
import EditActivity from "./EditActivity";
import ActivityList from "./ActivityList";
import { QuickPrint } from "./QuickPrint";
import { QuickUpdate } from "./QuickUpdate";
import { AddActivity } from "./AddActivity";
import { showQuickUpdate, showQuickprint } from "store/dashboard";
import { useDispatch } from "react-redux";
import { FolderOpenIcon, PrinterIcon } from "@heroicons/react/20/solid";
import { AvatarCalculate } from "sections/dashboard/components/AvatarCalculate";
import { TActivtityHealth } from "types/dashboard";
import { Panel, PanelGroup } from "rsuite";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";

const Activity = () => {
  let param = useParams();
  const dispatch = useDispatch();

  const { data: activityData } = useDashboardFetchDelegateDataQuery({
    key: param?.key ?? "",
    type: param?.type ?? "",
  });

  let healthData: TActivtityHealth = getActivityHealthList();
  return (
    <>
      <PanelGroup accordion defaultActiveKey={2} bordered>
        <div className="single-user-wrapper bg-white p-3 rounded-xl ">
          <Panel
            header={
              <div className="flex flex-row justify-between items-center my-1">
                <h1 className="font-bold text-lg p-7">Activity</h1>
              </div>
            }
            eventKey={2}
            id="panel3"
            className="dashboard-accordion-panel"
          >
            <hr className="mx-auto mt-2 mb-0 bg-blue border-blue-600 border-1 dark:bg-blue-700 "></hr>
            <div className="grid lg:grid-cols-2 md-g my-5">
              <div className="flex flex-wrap">
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-orange-500  border-orange-500 px-7 py-2 mr-2  mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.twenty}
                  </button>
                  <label>20%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-amber-300  border-amber-300 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default ">
                    {healthData?.fourty}
                  </button>
                  <label>40%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-purple-400  border-purple-400 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.sixty}
                  </button>
                  <label>60%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-cyan-400  border-cyan-400 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.eighty}
                  </button>
                  <label>80%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-lime-400  border-lime-400 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.hundrade}
                  </button>
                  <label>100%</label>
                </div>
              </div>
              <div className="right-col flex md-justify-end flex-wrap h-min ">
                <button
                  onClick={() => dispatch(showQuickprint(true))}
                  className="border min-w-[120px] rounded-full bg-violet-500 border-violet-500 hover:bg-white hover:text-violet-500 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white flex transition-all ease-in-out duration-500"
                >
                  <PrinterIcon className="w-5 h-5 mr-1" />
                  Quick Print
                </button>
              </div>
            </div>

            <ActivityList />
            <EditActivity />
            <QuickPrint />
          </Panel>
        </div>
      </PanelGroup>
    </>
  );

  function getActivityHealthList() {
    let activityPercentageTwenty = 0;
    let activityPercentageFourty = 0;
    let activityPercentageSixty = 0;
    let activityPercentageEighty = 0;
    let activityPercentageHundrad = 0;

    activityData?.dataArray?.map((options: any) => {
      options?.map((option: any) => {
        let finalPercentage =
          AvatarCalculate(new Date(option?.endDate))?.finalPercentage ?? 0;
        if (finalPercentage == 100) activityPercentageHundrad++;
        if (finalPercentage == 80) activityPercentageEighty++;
        if (finalPercentage == 60) activityPercentageSixty++;
        if (finalPercentage == 40) activityPercentageFourty++;
        if (finalPercentage == 20) activityPercentageTwenty++;
      });
    });
    return {
      hundrade: activityPercentageHundrad,
      eighty: activityPercentageEighty,
      sixty: activityPercentageSixty,
      fourty: activityPercentageFourty,
      twenty: activityPercentageTwenty,
    };
  }
};

export default Activity;
