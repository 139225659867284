import { useMemo, useState } from "react";
import { ListOnItemsRenderedProps } from "react-window";
import { useGetUserQuery } from "store/auth/authApi";
import { useGetVmapListQuery } from "store/preferences/profiles/profileApi";
import { IVmap } from "types/preferences/definition";
import { roles } from "utils/constants";
interface IvmapListHook {
  combinedVmapList: IVmap[];
  vmapList: IVmap[] | undefined;
  fetchMoreVmap: (_props: ListOnItemsRenderedProps) => void | undefined;
  mergedVmapValues: IVmap["values"];
  defaultVmap: IVmap;
  showCompletedLevels: number;
  showVMapProfile: boolean;
}
export default function useInfiniteVmapList({
  initialPageRange,
  include,
  endPoint,
}: {
  initialPageRange: [number, number];
  include?: string[];
  endPoint?: string;
}): IvmapListHook {
  const [pageRange, setPageRange] = useState<{
    page: [number, number];
    data: IVmap[];
  }>({
    page: initialPageRange,
    data: [],
  });

  const { data: userData } = useGetUserQuery();
  // TODO try to make infinite scroll more efficient
  const { data: vmapList } = useGetVmapListQuery({
    vmapPageSize: pageRange.page,
    include,
    endPoint,
  });

  // @ts-ignore
  const combinedVmapList: {
    vmapList: IVmap[];
    mergedVmapValues: IVmap["values"] | [];
    defaultVmap: IVmap;
  } = useMemo(
    () => ({
      vmapList: vmapList ? vmapList : [...pageRange.data],
      mergedVmapValues: (vmapList ? vmapList : [...pageRange.data]).reduce(
        // here we are providing the parent index for every vmap level so that it can be used to move element
        // @ts-ignore
        (acc, item, index) => [
          ...acc,
          ...item.values.map((item) => ({ ...item, parentIndex: index })),
        ],
        []
      ),
      defaultVmap: vmapList
        ? vmapList.findIndex((item) => {
            return item.vMapId === userData?.profile?.defaultVmap;
          })
        : [...pageRange.data].findIndex((item) => {
            return item.vMapId === userData?.profile?.defaultVmap;
          }),
    }),
    [vmapList]
  );
  const fetchMoreVmap = (props: ListOnItemsRenderedProps): void => {
    // REMOVE
    if (pageRange.page[1] >= 100) return;
    if (vmapList && props.visibleStopIndex >= vmapList?.length - 1) {
      // TODO try to use debouncing because this function will run on every scroll
      //@ts-ignore
      setPageRange((prev) => ({
        page: [1, 100],
        data: [...combinedVmapList.vmapList],
      }));
    }
  };

  return {
    combinedVmapList: combinedVmapList.vmapList,
    vmapList,
    mergedVmapValues: combinedVmapList.mergedVmapValues,
    fetchMoreVmap,
    defaultVmap: combinedVmapList.defaultVmap,
    showCompletedLevels: userData?.profile.completeIteminVmap ? 2 : 0,
    showVMapProfile: userData?.role_id == roles["Super Admin"],
  };
}
